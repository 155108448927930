<template>
  <div>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              약관 리스트 [{{ totalRecords }}] 건
            </h5>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refTermsTable"
        primary-key="id"
        :items="fetchTerms"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        @row-selected="onRowSelectedTerms"
      >
        <template #cell(startDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD') }}
          </span>
        </template>
        <template #cell(endDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD') }}
          </span>
        </template>

      </b-table>
    </b-card>

    <b-card
      class="mt-2"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start"
        >
          <feather-icon
            icon="EditIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            상세
          </h4>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-end"
        >
          <b-button
            variant="outline-primary"
            @click.prevent="resetTermsData()"
          >
            <feather-icon
              icon="PlusSquareIcon"
              class="mr-50"
            />
            <span>신규 등록</span>
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            @click.prevent="saveTerms()"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span>저장</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="10"
        >
          <b-form-group
            label="약관 종류"
            label-for="termsData-termsType"
          >
            <b-form-radio-group
              v-model="termsData.termsType"
              :options="codes.termsType"
              value-field="code"
              text-field="label"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <validation-observer ref="formRules">
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="컨텐츠"
              label-for=""
            >
              <b-form-file
                ref="contentFile"
                accept="text/plain,.html"
                drop-placeholder="Drop file here..."
                @input="readContentFile"
              />
              <validation-provider
                #default="{ errors }"
                name="컨텐츠"
                rules="required"
              >
                <b-form-input
                  v-show="false"
                  v-model="termsData.contents"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            md="1"
          >
            <b-button
              variant="primary"
              class="mr-sm-50"
              style="margin-top: 28px"
              :disabled="termsData.contents === null"
              @click="showModal = true"
            >
              <span>미리보기</span>
            </b-button>
            <b-modal
              v-model="showModal"
              size="xl"
              title="HTML 미리보기"
            >
              <iframe
                ref="previewFrame"
                :src="previewUrl"
                frameborder="0"
                width="1400"
                height="740"
                @load="loadPreview"
              />
            </b-modal>
          </b-col>
          <b-col style="margin-left: -25px">
            <b-button
              v-if="termsData.id"
              variant="outline-dark"
              class="mr-sm-50"
              style="margin-top: 28px"
              @click="downloadContent"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>다운로드</span>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="적용 시작일"
              label-for="popup-date"
              rules="required"
            >
              <validation-provider
                #default="{ errors }"
                name="적용 시작일"
                rules="required"
              >
                <b-form-datepicker
                  v-model="termsData.startDate"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'
import { ref, onMounted, getCurrentInstance } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BFormFile } from "bootstrap-vue"
import { errorFormatter } from '@core/utils/filter'
import { saveAs } from "file-saver"
import dayjs from 'dayjs'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormFile,
  },

  setup() {
    onMounted(() => {
    })

    const previewUrl = `${process.env.VUE_APP_PREVIEW_URL}/#/app/preview`
    const previewFrame = ref(null)
    const showModal = ref(false)
    const contentFile = ref(null)
    const toast = useToast()
    const instance = getCurrentInstance()
    const refs = instance.proxy.$refs

    const termsInitState = {
      id: null,
      termsType: 1,
      termsTypeName: null,
      contents: null,
      startDate: null,
      endDate: null,
    }
    const termsData = ref({ ...termsInitState })

    const resetTermsData = () => {
      contentFile.value.reset()

      Object.assign(termsData.value, termsInitState)

      refs.formRules.reset()
      refTermsTable.value.clearSelected()
    }

    // Set Codes
    const codes = ref({
      termsType: [
        { label: '통합 서비스 이용약관', code: 1 },
        { label: '개인정보처리방침', code: 2 },
      ],
    })

    // Main Table Config
    const refTermsTable = ref(null)
    const sortBy = ref('id')
    const isSortDesc = ref(true)
    const totalRecords = ref(0)
    const tableColumns = [
      {
        key: 'id', label: 'ID', sortable: true, thStyle: { width: '7%' },
      },
      { key: 'termsTypeName', label: '약관종류', sortable: true },
      { key: 'startDate', label: '시작일', sortable: true },
      { key: 'endDate', label: '종료일', sortable: true },
    ]

    const refetchData = () => {
      refTermsTable.value.refresh()
    }

    const onRowSelectedTerms = items => {
      const item = items[0]

      if (item && item.id) {
        fetchTerm(item.id)
      }
    }

    // Main Table Config End.
    const fetchTerm = id => {
      axios.get(`/fa/terms/${id}`)
        .then(rs => {
          termsData.value = rs.data
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 조회에 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const fetchTerms = (ctx, callback) => {
      axios.post('/fa/terms/list', {
        search: {
        },
        sort: {
          predicate: sortBy.value,
          reverse: isSortDesc.value,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data

          callback(items)

          totalRecords.value = totalRecord

          resetTermsData()
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 조회에 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const saveTerms = () => {
      const validPromise = refs.formRules.validate()

      validPromise.then(valid => {
        if (!valid) return

        const { id } = termsData.value

        if (id) {
          updateTerms()
        } else {
          saveNewTerms()
        }
      })
    }

    const saveNewTerms = () => {
      axios.put('/fa/terms/new', termsData.value)
        .then(rs => {
          toast({
            component: ToastificationContent,
            props: {
              title: '신규 약관 등록 완료',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })

          if (rs.data.id) {
            termsData.value.id = rs.data.id
          }

          refetchData()
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '데이터를 저장하는 과정에서 오류가 발생하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const updateTerms = () => {
      axios.patch(`/fa/terms/update/${termsData.value.id}`, termsData.value)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 변경 완료',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })

          refetchData()
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '데이터를 변경하는 과정에서 오류가 발생하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const readContentFile = e => {
      if (!e) return
      if (e.type !== 'text/html') {
        toast({
          component: ToastificationContent,
          props: {
            title: '잘못된 파일 형식입니다.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })

        contentFile.value.reset()
      } else {
        const reader = new FileReader()

        reader.onload = event => {
          termsData.value.contents = event.target.result
        }

        reader.readAsText(e)
      }
    }

    const loadPreview = e => {
      if (e.type === 'load') previewFrame.value.contentWindow.postMessage(termsData.value.contents, '*')
    }

    const downloadContent = () => {
      // window.location.href = `/fa/terms/${termsData.value.id}/file`

      axios.get(`/fa/terms/${termsData.value.id}/file`, { responseType: 'blob' })
        .then(rs => {
          const mimeType = {
            type: "text/plain",
          }

          const blob = new Blob([rs.data], mimeType)

          const fileName = codes.value.termsType.filter(e => e.code === termsData.value.termsType)[0].label

          saveAs(blob, `${fileName}_${dayjs(termsData.value.startDate).format('YYYY_MM_DD')}.html`)
        })
        .catch(() => {
        })
    }

    return {
      codes,
      termsData,
      tableColumns,
      refTermsTable,
      sortBy,
      isSortDesc,
      required,
      refetchData,
      totalRecords,
      fetchTerm,
      fetchTerms,
      resetTermsData,
      onRowSelectedTerms,
      saveTerms,
      updateTerms,
      readContentFile,
      contentFile,
      showModal,
      previewFrame,
      previewUrl,
      loadPreview,
      downloadContent,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style>
.modal-xl {
  margin-left: auto;
  margin-right: auto;
  width: 1440px;
}
</style>
